import { useField } from 'formik';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';

import { CustomLink } from '@shared-atom/elpaso-kit/custom-link';
import { LinkSizeEnum, LinkTypeEnum } from '@shared-atom/elpaso-kit/custom-link/types';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { Account, Fee } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { FinallyAction } from '@component/modal/modals/common/finally-action/finally-action';
import { PaymentConfirmation } from '@component/modal/modals/payment/payment-confirmation/payment-confirmation';
import { PaymentToCardConfirmation } from '@component/modal/modals/payment/payment-confirmation/payment-to-card-confirmation';
import { PaymentOtpForm } from '@component/modal/modals/payment/payment-update-steps/payment-otp-form/payment-otp-form';
import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';
import { PaymentUpdateStepsButtons } from '@component/modal/modals/payment/payment-update-steps/payment-update-steps-buttons/payment-update-steps-buttons';
import {
    StepsWrapper,
    TotalAmount,
} from '@component/modal/modals/payment/payment-update-steps/payment-update-steps.style';
import { supportMailLink } from '@page/main-pages/main-page-common/main-page-common.constants';
import { useUserSelector } from '@selector/user/use-user.selector';

interface PaymentUpdateStepsProps {
    fee: Fee;
    step: PaymentUpdateStepsEnum;
    selectedAccount?: Account;
    signPaymentError?: string;
    setSignPaymentError: Dispatch<SetStateAction<string>>;
    onPrevStep: OnEventEmptyType;
    onPaymentSign: OnEventEmptyType;
    onOtpSubmit: OnEventType<string>;
    isLoading: boolean;
    isSignMode: boolean;
    canSign: boolean;
    isPaymentError?: boolean;
    setModalTitle: Dispatch<SetStateAction<LocalizationEnum>>;
    ibanPaymentFlow?: boolean;
    currency: string;
    onSendOtpCode: OnEventEmptyType;
}

export const PaymentUpdateSteps = ({
    currency,
    step,
    fee,
    selectedAccount,
    signPaymentError,
    setSignPaymentError,
    onPrevStep,
    onOtpSubmit,
    onPaymentSign,
    isLoading,
    isSignMode,
    canSign,
    setModalTitle,
    isPaymentError = false,
    ibanPaymentFlow = false,
    onSendOtpCode,
}: PaymentUpdateStepsProps) => {
    const [{ value: amount }] = useField('amount');

    const totalAmount = useCurrencyFormat(fee.total, currency);
    const successCreatedActionText = useMemo(
        () =>
            ibanPaymentFlow ? (
                <>
                    <Translate langKey={LocalizationEnum.PaymentSignedDescription1} />
                    <TotalAmount> {totalAmount} </TotalAmount>
                    <Translate langKey={LocalizationEnum.PaymentSignedDescription2} />
                </>
            ) : (
                <Translate langKey={LocalizationEnum.PaymentToCardCreatedInTaskList} />
            ),
        [totalAmount, ibanPaymentFlow]
    );
    const successSignedActionText = useMemo(
        () =>
            ibanPaymentFlow ? (
                <>
                    <Translate langKey={LocalizationEnum.PaymentSignedDescription1} />
                    <TotalAmount> {totalAmount} </TotalAmount>
                    <Translate langKey={LocalizationEnum.PaymentSignedDescription3} />
                </>
            ) : (
                <Translate langKey={LocalizationEnum.PaymentToCardIsBeingProcessed} />
            ),
        [totalAmount, ibanPaymentFlow]
    );
    const paymentErrorText = useMemo(
        () => (
            <>
                <Translate langKey={LocalizationEnum.PaymentErrorText} />{' '}
                <CustomLink
                    title={LocalizationEnum.CommonSupportTeam}
                    size={LinkSizeEnum.XL}
                    type={LinkTypeEnum.PRIMARY}
                    href={supportMailLink}
                />
            </>
        ),
        []
    );

    const { setModalHeight } = useContext(ModelContext);
    const [{ phone }] = useUserSelector();
    const paymentErrorBtnText = useLocalizationText(LocalizationEnum.CommonRepeatTransaction);
    const paymentSuccessBtnText = useLocalizationText(LocalizationEnum.PrivateFormButtonContinueTitle);

    const isInsufficientFunds = useMemo(
        () => selectedAccount !== undefined && isExist(amount) && selectedAccount.balance < fee.total,
        [selectedAccount, amount, fee.total]
    );
    const balanceAfterTransaction = useMemo(
        () =>
            selectedAccount !== undefined && isExist(amount) && Boolean(fee?.total) === true
                ? selectedAccount.balance - fee.total
                : 0,
        [selectedAccount, amount, fee.total]
    );

    const isPaymentSigned = step === PaymentUpdateStepsEnum.PaymentSigned;
    const isPaymentCreated = step === PaymentUpdateStepsEnum.PaymentCreated;
    const isOtpStep = step === PaymentUpdateStepsEnum.Otp;
    const ConfirmationComponent = ibanPaymentFlow ? PaymentConfirmation : PaymentToCardConfirmation;

    useEffect(() => {
        if (step === PaymentUpdateStepsEnum.ConfirmPayment && !isSignMode) {
            setModalTitle(LocalizationEnum.PaymentFormConfirmationStepTitle);
        }
        if (isPaymentCreated) {
            setModalHeight(ModalHeightEnum.Half);
            setModalTitle(LocalizationEnum.PaymentCreatedTitle);
        }
        if (isPaymentSigned) {
            setModalHeight(ModalHeightEnum.Half);
            setModalTitle(LocalizationEnum.PaymentSignedTitle);
        }
        if (isOtpStep) {
            setModalHeight(ModalHeightEnum.Half);
            setModalTitle(LocalizationEnum.SmsVerificationTitle);
        }
        if (isPaymentError) {
            setModalHeight(ModalHeightEnum.Half);
            setModalTitle(LocalizationEnum.CommonSystemError);
        }

        return () => {
            setModalTitle(LocalizationEnum.PaymentFormTitle);
        };
    }, [step, isSignMode, isPaymentCreated, isPaymentSigned, isPaymentError]);

    return (
        <StepsWrapper ibanPaymentFlow={ibanPaymentFlow || step !== PaymentUpdateStepsEnum.ConfirmPayment}>
            {step === PaymentUpdateStepsEnum.ConfirmPayment && !isPaymentError && (
                <ConfirmationComponent
                    currency={currency}
                    fee={fee}
                    isInsufficientFunds={isInsufficientFunds}
                    balanceAfterTransaction={balanceAfterTransaction}
                >
                    <PaymentUpdateStepsButtons
                        isSignMode={isSignMode}
                        onPaymentSign={onPaymentSign}
                        onBack={onPrevStep}
                        isPaymentSigned={isPaymentSigned}
                        isLoading={isLoading}
                        isDisabled={isInsufficientFunds || !canSign}
                        ibanPaymentFlow={ibanPaymentFlow}
                    />
                </ConfirmationComponent>
            )}
            {isOtpStep && (
                <PaymentOtpForm
                    onChange={setSignPaymentError}
                    error={signPaymentError}
                    phone={phone}
                    onSubmit={onOtpSubmit}
                    isLoading={isLoading}
                    onResendOtpCode={onSendOtpCode}
                />
            )}
            {isPaymentCreated && (
                <FinallyAction successMessage={successCreatedActionText} successBtnText={paymentSuccessBtnText} />
            )}
            {isPaymentSigned && (
                <FinallyAction successMessage={successSignedActionText} successBtnText={paymentSuccessBtnText} />
            )}
            {isPaymentError && (
                <FinallyAction
                    error={isPaymentError}
                    errorMessage={paymentErrorText}
                    errorBtnText={paymentErrorBtnText}
                />
            )}
        </StepsWrapper>
    );
};
