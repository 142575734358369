import { useField } from 'formik';
import { useEffect, useMemo } from 'react';

import { getAccountIdsOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist } from '@shared-util/is-data';

import { PaymentToSendsDetailsFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form.interface';
import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';

export const usePaymentToSendsDetailsForm = ({
    accounts,
    fee: { total, amount },
    selectedAccount,
}: PaymentToSendsDetailsFormProps) => {
    const accountOptionsSell = getAccountIdsOptions(accounts, true);
    const insufficientFundsErrorText = useLocalizationText(LocalizationEnum.CommonInsufficientFunds);

    const [{ value: paymentCurrency }] = useField(PaymentToSendsFormEnum.Currency);
    const [, , { setError }] = useField(PaymentToSendsFormEnum.Amount);

    const totalAmount = useCurrencyFormat(total, paymentCurrency);
    const paymentFee = useCurrencyFormat(amount, paymentCurrency);

    const isInsufficientFunds = useMemo(
        () => selectedAccount !== undefined && isExist(amount) && selectedAccount.balance < total,
        [selectedAccount, amount, total]
    );

    useEffect(() => {
        setError(isInsufficientFunds ? insufficientFundsErrorText : undefined);
    }, [isInsufficientFunds]);

    return {
        accountOptionsSell,
        paymentCurrency,
        paymentFee,
        totalAmount,
    };
};
