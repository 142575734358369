import React from 'react';

import { CustomInputAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { CustomTextarea } from '@shared-atom/elpaso-kit/input/custom-textarea/custom-textarea';
import { SelectAccount } from '@shared-atom/elpaso-kit/select-account/select-account';
import { PaymentFiles } from '@shared-component/payment-files/payment-files';
import { Scroll } from '@shared-component/scroll/scroll';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { TEXT_AREA_LENGTH } from '@shared-util/constants';
import { getCurrencyFormat } from '@shared-util/get-currency-format';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { usePaymentToSendsDetailsForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form.hook';
import { PaymentToSendsDetailsFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form.interface';
import {
    FormDetailsWrapper,
    FormSectionWrapper,
} from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form.styles';
import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';
import { PAYMENT_DOCUMENT_SIZE } from '@component/modal/modals/payment/payment.modal-options';
import { FormComponentWrapper } from '@component/modal/modals/payment/payment.modal-style';

export const PaymentToSendsDetailsForm = ({ accounts, fee, selectedAccount }: PaymentToSendsDetailsFormProps) => {
    const { accountOptionsSell, paymentCurrency, paymentFee, totalAmount } = usePaymentToSendsDetailsForm({
        accounts,
        fee,
        selectedAccount,
    });

    return (
        <FormDetailsWrapper>
            <Scroll>
                <FormComponentWrapper>
                    <FormRow>
                        <SelectAccount
                            label={LocalizationEnum.AccountYouPayFrom}
                            title={LocalizationEnum.AccountYouPayFrom}
                            name={PaymentToSendsFormEnum.AccountId}
                            options={accountOptionsSell}
                            rowCountView={3}
                            isMobile
                            isDisabled
                        />
                    </FormRow>
                    <FormRow>
                        <CustomInput
                            label={LocalizationEnum.CommonRecipient}
                            name={PaymentToSendsFormEnum.EmailOrIban}
                            placeholder={LocalizationEnum.EmailOrSendsIban}
                            isMobile
                            isSpacecDisabled
                        />
                    </FormRow>
                    <FormRow>
                        <CustomInputAmount
                            title={LocalizationEnum.CommonAmount}
                            name={PaymentToSendsFormEnum.Amount}
                            placeholder={getCurrencyFormat(100, paymentCurrency)}
                            isMobile
                        />
                    </FormRow>
                    <FormRow>
                        <CustomTextarea
                            label={LocalizationEnum.PaymentFormDetailsStepDescription}
                            placeholder={LocalizationEnum.PaymentFormDetailsStepPlaceholder}
                            name={PaymentToSendsFormEnum.Reason}
                            maxLength={TEXT_AREA_LENGTH}
                            isMobile
                        />
                    </FormRow>
                    <PaymentFiles
                        name={PaymentToSendsFormEnum.AttachedFiles}
                        limit={PAYMENT_DOCUMENT_SIZE}
                        title={LocalizationEnum.OptionallyAttachFile}
                    />
                </FormComponentWrapper>
                <FormSectionWrapper>
                    <InformationCopyRow title={LocalizationEnum.CommonPaymentFee} data={paymentFee} isCopied={false} />
                    <InformationCopyRow
                        title={LocalizationEnum.PaymentFormCurrencyAmountStepPayIn}
                        data={totalAmount}
                        isCopied={false}
                    />
                </FormSectionWrapper>
            </Scroll>
        </FormDetailsWrapper>
    );
};
