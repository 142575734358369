import { FC, SVGProps } from 'react';

import Facebook from '@page/main-pages/assets/facebook.svg?react';
import Instagram from '@page/main-pages/assets/instagram.svg?react';
import Linkedin from '@page/main-pages/assets/linkedin.svg?react';
import Medium from '@page/main-pages/assets/medium.svg?react';

export interface SocialOptionInterface {
    link: string;
    Icon: FC<SVGProps<SVGSVGElement>>;
}

export const contactMail = 'contact@sends.co';

export const prAndMarketingMail = 'pr@sends.co';
export const supportMail = 'support@sends.co';

export const contactMailLink = `mailto:${contactMail}`;

export const prAndMarketingMailLink = `mailto:${prAndMarketingMail}`;
export const supportMailLink = `mailto:${supportMail}`;

export const phoneNumber = '+44 7857 291 637';
export const phoneNumberLink = `tel:${phoneNumber.replace(/\s/g, '')}`;

export const companyAddress = `Office 39.18, Level39, One Canada Square, London, England, E14 5AB`;
export const addressLink = `https://maps.app.goo.gl/X5NLcaLtxYRKGzeKA`;

export const facebookLink = 'https://www.facebook.com/sends.co';
export const linkedinLink = 'https://www.linkedin.com/company/sends-co/';
export const instagramLink = 'https://www.instagram.com/sends.co/';
export const mediumLink = 'https://medium.com/@sends.co';
export const blogLink = 'https://sends.co/blog';

export const socialOptions: SocialOptionInterface[] = [
    {
        link: facebookLink,
        Icon: Facebook,
    },
    {
        link: linkedinLink,
        Icon: Linkedin,
    },
    {
        link: instagramLink,
        Icon: Instagram,
    },
    {
        link: mediumLink,
        Icon: Medium,
    },
];
