import { types } from 'mobx-state-tree';

import { CurrencyModelType } from '../../currency-model/currency-model.type';

export const AccountBankTypeSchema = {
    bankAddress: types.string,
    bankCountry: types.string,
    bankName: types.string,
    beneficiaryAddress: types.maybeNull(types.string),
    bic: types.maybeNull(types.string),
    countrySpecificDetails: types.maybeNull(types.string),
    currency: CurrencyModelType,
    iban: types.maybeNull(types.string),
    name: types.string,
    number: types.string,
    paymentType: types.string,
    reference: types.maybeNull(types.string),
    sortCode: types.maybeNull(types.string),
};
